import html from './index.html';
import styles from './index.scss';

/**
 * Renders generic 3dots loading indicator, mostly used for visualizing async operations
 */
class LoadingElement extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'loading-element': LoadingElement;
  }
}

if (!customElements.get('loading-element')) {
  customElements.define('loading-element', LoadingElement);
}
